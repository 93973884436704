<template>
  <div v-if="step" class="step__body">
    <h3>{{ step.name }}</h3>
    <div class="row">
      <div class="col-sm-12">
        <div v-html="step.description"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    return {
      step
    }
  }
}
</script>
